import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '../i18n'

import Home from '../views/Home.vue'
import Sign from '../views/Sign.vue'

Vue.use(VueRouter)

// 메인
  const Dashboard = () => import(/* webpackChunkName: "main" */ '../views/DashBoard.vue')
  const Main = () => import(/* webpackChunkName: "main" */ '../components/main/Main.vue')
  const Certification = () => import(/* webpackChunkName: "main" */ '../components/email/Certification.vue')

// Board
const Notice = () => import(/* webpackChunkName: "main" */ '../views/Notice.vue')
const NoticeDetail = () => import(/* webpackChunkName: "main" */ '../views/NoticeDetail.vue')

const Shared = () => import(/* webpackChunkName: "main" */ '../views/Shared.vue')
const SharedDetail = () => import(/* webpackChunkName: "main" */ '../views/SharedDetail.vue')

const Inquiry = () => import(/* webpackChunkName: "inquiry" */ '../views/Inquiry.vue')
const InquiryDetail = () => import(/* webpackChunkName: "inquiryDetail" */ '../views/InquiryDetail.vue')
const InquiryCreate = () => import(/* webpackChunkName: "inquiryCreate" */ '../views/InquiryCreate.vue')

// Matrix
  const ReferralTree = () => import(/* webpackChunkName: "Matrix" */ '../views/ReferralTree.vue')
  const SupportTree = () => import(/* webpackChunkName: "Matrix" */ '../views/SupportTree.vue')


// Package
  const MemberShip = () => import(/* webpackChunkName: "MemberShip" */ '../views/MemberShip.vue')
  const Transfer = () => import(/* webpackChunkName: "Transaction" */ '../views/Transfer.vue')
  const Withdraw = () => import(/* webpackChunkName: "Transaction" */ '../views/Withdraw.vue')
  const WithdrawCoin = () => import(/* webpackChunkName: "Transaction" */ '../views/WithdrawCoin.vue')
  const Deposit = () => import(/* webpackChunkName: "Deposit" */ '../views/Deposit.vue')


// History
  const CoinList = () => import(/* webpackChunkName: "Coin" */ '../views/CoinList.vue')
  const AssetsList = () => import(/* webpackChunkName: "History" */ '../views/AssetsList.vue')
  const NccAssetsList = () => import(/* webpackChunkName: "History" */ '../views/NccAssetsList.vue')
  
  const SalesList = () => import(/* webpackChunkName: "History" */ '../views/SalesList.vue')
  const CoinHistory = () => import(/* webpackChunkName: "Coin" */ '../views/CoinHistory.vue')
  const BonusHistory = () => import(/* webpackChunkName: "History" */ '../views/BonusList.vue')
  const ReferralHistory = () => import(/* webpackChunkName: "History" */ '../views/RefferalList.vue')
  const SupportHistory = () => import(/* webpackChunkName: "History" */ '../views/SupportList.vue')
  const MatchHistory = () => import(/* webpackChunkName: "History" */ '../views/MatchList.vue')
  const StarHistory = () => import(/* webpackChunkName: "History" */ '../views/StarList.vue')
  const MemberHistory = () => import(/* webpackChunkName: "History" */ '../views/MemberList.vue')
  const RollupHistory = () => import(/* webpackChunkName: "History" */ '../views/RollupList.vue')

// Coin
const CoinSend = () => import(/* webpackChunkName: "Coin" */ '../views/CoinSend.vue')
const CoinReceive = () => import(/* webpackChunkName: "Coin" */ '../views/CoinReceive.vue')



// Profile
  const ChangePw = () => import(/* webpackChunkName: "Profile" */ '../views/PasswordChange.vue')
  const ChangePin = () => import(/* webpackChunkName: "Profile" */ '../views/PinChange.vue')
  const Profile = () => import(/* webpackChunkName: "Profile" */ '../views/Profile.vue')
  const OTP = () => import(/* webpackChunkName: "Profile" */ '../views/OTP.vue')


// 로그인/회원가입
  const Login = () => import(/* webpackChunkName: "sign" */ '../components/sign/Login.vue')
  const SignUp = () => import(/* webpackChunkName: "sign" */ '../components/sign/SignUp.vue')
  const FindPW = () => import(/* webpackChunkName: "sign" */ '../components/sign/FindPW.vue')


// 가이드
const Guide = () => import(/* webpackChunkName: "guide" */ '../views/Guide.vue')
const GuidePage = () => import(/* webpackChunkName: "guide" */ '../views/GuidePage.vue')

const Term = () => import(/* webpackChunkName: "Term" */ '../components/term/Main.vue')

  const routes = [
    { path: '/', redirect: `/${i18n.locale}/` },
    { path: '/:lang/', name: 'main', component: Main },
    { path: '/:lang/sign', name: 'sign', component: SignUp },
    { path: '/:lang/sign/:id/:line', name: 'signid', component: SignUp },
    { path: '/:lang/find-pw', name: 'find-pw', component: FindPW },
    { path: '/:lang/login', name: 'login', component: Login },
    { path: '/:lang/dashboard', name: 'dashboard', component: Dashboard},
    { path: '/:lang/certification', name: 'certification', component: Certification},
    { path: '/:lang/notice/:code', name: 'noticedetail',component: NoticeDetail},
    { path: '/:lang/shared', name: 'shared',component: Shared},
    { path: '/:lang/shared/:code', name: 'shareddetail',component: SharedDetail},
    { path: '/:lang/inquiry', name: 'inquiry',component: Inquiry},
    { path: '/:lang/inquiry/new', name: 'inquiryCreate',component: InquiryCreate},
    { path: '/:lang/inquiry/detail/:code', name: 'inquirydetail',component: InquiryDetail},
    { path: '/:lang/notice', name: 'notice',component: Notice},
    { path: '/:lang/changePw', name: 'changepassword',component: ChangePw},
    { path: '/:lang/changePin', name: 'changepin',component: ChangePin},
    { path: '/:lang/profile', name: 'profile',component: Profile},
    { path: '/:lang/otp', name: 'otp',component: OTP},
    { path: '/:lang/NccAssetsList', name: 'coinlist',component: NccAssetsList},
    { path: '/:lang/AssetsHistory', name: 'assetslist',component: AssetsList},
    { path: '/:lang/SalesList', name: 'saleslist',component: SalesList},
    { path: '/:lang/BonusHistory', name: 'bonuslist',component: BonusHistory},
    { path: '/:lang/ReferralHistory', name: 'ReferralHistory',component: ReferralHistory},
    { path: '/:lang/SupportHistory', name: 'SupportHistory',component: SupportHistory},
    { path: '/:lang/MatchHistory', name: 'MatchHistory',component: MatchHistory},
    { path: '/:lang/StarHistory', name: 'StarHistory',component: StarHistory},
    { path: '/:lang/MemberHistory', name: 'MemberHistory',component: MemberHistory},
    { path: '/:lang/RollupHistory', name: 'RollupHistory',component: RollupHistory},
    { path: '/:lang/ReferralTree', name: 'ReferralTree',component: ReferralTree},
    { path: '/:lang/SupportTree', name: 'SupportTree',component: SupportTree},
    { path: '/:lang/membership', name: 'membership',component: MemberShip},
    { path: '/:lang/CoinList', name: 'CoinList',component: CoinList},
    { path: '/:lang/CoinHistory/:symbol', name: 'CoinHistory',component: CoinHistory},
    { path: '/:lang/CoinSend/:symbol', name: 'CoinSend',component: CoinSend},
    { path: '/:lang/CoinReceive/:symbol', name: 'CoinReceive',component: CoinReceive},
    
    { path: '/:lang/Transfer', name: 'Transfer',component: Transfer},
    { path: '/:lang/Deposit', name: 'Deposit',component: Deposit},
    { path: '/:lang/Withdraw', name: 'Withdraw',component: Withdraw},
    { path: '/:lang/Withdraw-coin', name: 'WithdrawCoin',component: WithdrawCoin},
    
    { path: '/:lang/guide', name: 'Guide',component: Guide},
    { path: '/:lang/guide/:idx', name: 'GuidePage',component: GuidePage},
    { path: '/:lang/term', name: 'Term',component: Term},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
